import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';
import { Box, Grid, GridItem, Link, Stack, Text } from '@chakra-ui/react';
import Bugsnag from '@bugsnag/js';
import classNames from 'classnames';
import _ from 'lodash';

import { getLinkByPageName, getLinkWithoutDomain } from '../../lib/links';
import { SaveContactData } from '../../storage/provider';
import { getContactMutation } from '../../lib/graphql';
import PropsContact from '../../interface/template';
import {
  getContactFormPardotParams,
  SalesforceContactInterface,
} from '../../lib/salesforce-integration';
import Wrapper from './styles';
import Navbar from '../../components/NavBar';
import { Text5 } from '../../components/atoms/body';
import {
  ContactInfo,
  Error,
  FormLayout,
  generateDefaultState,
  InputButton,
  InputCheckbox,
  InputDropdown,
  InputPhone,
  InputText,
  isFormValid,
} from '../../components/Forms';
import FAQ from '../../components/FAQ';
import Footer from '../../components/Footer';
import config from './config.json';
import { getGaConnectorValues } from '../../lib/ga-connector';
import DataLayer from '../../components/DataLayer';
import * as gaEvents from './ga_events';
import useUserLocation from '../../hooks/useUserLocation';
import { getClientId } from '../../lib/experiments';
import { isValidPhone } from '../../components/Forms/fields/_helpers';
import Banner from '../../components/Banner';

const ContentHeader = (props: {
  title: string;
  subtitle: string;
}): React.ReactElement => {
  const { title, subtitle } = props;

  return (
    <Stack as="section" color="darkBlues.100" spacing={1}>
      <Text
        as="h1"
        fontFamily="heading"
        fontSize={['3.8rem', '4.8rem']}
        fontWeight="semibold"
        textAlign={['left', 'center']}
      >
        {title}
      </Text>
      <Text align={['left', 'center']} color="darkBlues.64" textStyle="body3">
        {subtitle}
      </Text>
    </Stack>
  );
};

const defaultState = generateDefaultState(config);

const Contact: React.SFC<any> = (props: PropsContact) => {
  const { t } = useTranslation();
  const { pageContext } = props;
  const { locale, pageName } = pageContext;
  const { userLocation } = useUserLocation();
  const campus =
    userLocation.campusVisited || userLocation.defaultCampus || 'ams';

  const defaultFieldValues = { ...defaultState, campus };
  const [fieldValues, setFieldValues] = useState(defaultFieldValues);
  const [phoneCountry, setPhoneCountry] = useState<string>();
  const [shouldValidate, setShouldValidate] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [getUpdatedChecked, setGetUpdatedChecked] = useState(false);
  const [error, setError] = useState('');
  const termsAndConditionsClass = classNames({ invalid: shouldValidate });

  const redirectUrl = getLinkWithoutDomain('contact/contactThanks');

  const [dataLayer, updateDataLayer] = useState({});
  const [dataLayerIsPopulated, setDataLayerIsPopulated] = useState(false);

  function updateFieldValue(key: string, value: string): void {
    setFieldValues({ ...fieldValues, [key]: value });
  }

  useEffect(() => {
    if (userLocation.defaultCampus) {
      updateFieldValue(
        'campus',
        userLocation.campusVisited || userLocation.defaultCampus
      );
    }
  }, [userLocation.defaultCampus, userLocation.campusVisited]);

  useEffect(() => {
    const { campus: campusCode } = fieldValues;
    if (campusCode) updateDataLayer({ campusCode });
  }, [fieldValues]);

  useEffect(() => {
    if (!_.isEmpty(dataLayer)) setDataLayerIsPopulated(true);
  }, [dataLayer]);

  const campuses: Record<string, string> = {
    ams: t('breadcrumbs:breadcrumbs.campusAms'),
    bcn: t('breadcrumbs:breadcrumbs.campusBcn'),
    ber: t('breadcrumbs:breadcrumbs.campusBer'),
    lis: t('breadcrumbs:breadcrumbs.campusLis'),
    mad: t('breadcrumbs:breadcrumbs.campusMad'),
    mex: t('breadcrumbs:breadcrumbs.campusMex'),
    mia: t('breadcrumbs:breadcrumbs.campusMia'),
    par: t('breadcrumbs:breadcrumbs.campusPar'),
    rmt: t('breadcrumbs:breadcrumbs.campusRmt'),
    sao: t('breadcrumbs:breadcrumbs.campusSao'),
  };

  const i18n: {
    fields: Record<string, string>;
    placeholders: Record<string, string>;
  } = {
    fields: {
      button: t('contact-form:contactForm.fields.button'),
      campus: t('contact-form:contactForm.fields.campus'),
      email: t('contact-form:contactForm.fields.email'),
      firstName: t('contact-form:contactForm.fields.firstName'),
      lastName: t('contact-form:contactForm.fields.lastName'),
      message: t('contact-form:contactForm.fields.message'),
      phoneNumber: t('contact-form:contactForm.fields.phoneNumber'),
    },
    placeholders: {
      firstName: t('contact-form:contactForm.placeholders.firstName'),
      lastName: t('contact-form:contactForm.placeholders.lastName'),
      email: t('contact-form:contactForm.placeholders.email'),
      phoneNumber: t('contact-form:contactForm.placeholders.phoneNumber'),
      message: t('contact-form:contactForm.placeholders.message'),
    },
  };

  const validationErrors = isFormValid(fieldValues, config);

  async function handleSumit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    e.preventDefault();
    gaEvents.emitApplyEvent(fieldValues.campus);
    setError('');
    setIsSending(true);

    if (validationErrors.length === 0) {
      const { firstName: name, email, campus, course } = fieldValues;
      const salesforceParameters: SalesforceContactInterface = {
        campus,
        email,
        emailOptIn: getUpdatedChecked,
        firstName: name,
        ipCountry: userLocation.countryName,
        lastName: fieldValues.lastName,
        phone: fieldValues.phoneNumber,
        phoneCountry: phoneCountry?.toUpperCase(),
        message: fieldValues.message,
      };

      const gaConnector = getGaConnectorValues(document.cookie || '');
      const pardotParams = getContactFormPardotParams(
        salesforceParameters,
        locale,
        gaConnector
      );
      const gaClientId = await getClientId(2000);
      const query = getContactMutation(fieldValues, pardotParams, gaClientId);

      fetch(process.env.IRONHACK_API, query)
        .then((response) => {
          if (response.errors) throw response.errors;
          gaEvents.emitApplyOkEvent(fieldValues.campus, getUpdatedChecked);
          const applicationData = { name, email, campus, course };
          SaveContactData(applicationData);
          navigate(redirectUrl);
        })
        .catch((apiError) => {
          gaEvents.emitApplyKoEvent(fieldValues.campus);
          Bugsnag.notify(apiError, function (event) {
            event.addMetadata('Query', query);
          });
          setError(t('contact-form:contactForm.error'));
          setIsSending(false);
        });
    } else {
      gaEvents.emitApplyKoEvent(fieldValues.campus, validationErrors);
      setShouldValidate(true);
      setIsSending(false);
      setError(t('contact-form:contactForm.requiredFields'));
    }
  }

  function generateField(id: string): JSX.Element {
    switch (config[id].type) {
      case 'select':
        return (
          <InputDropdown
            id={id}
            key={id}
            label={i18n.fields[id]}
            options={campuses}
            setValue={updateFieldValue}
            shouldValidate={shouldValidate}
            value={fieldValues[id]}
            {...config[id]}
          />
        );
      case 'phone':
        return (
          <InputPhone
            id={id}
            isValid={isValidPhone(
              fieldValues[id],
              error && validationErrors.includes(id)
            )}
            key={id}
            label={i18n.fields[id]}
            noMarginBottom={false}
            onChange={(phone, country): void => {
              setPhoneCountry(country.name);
            }}
            setValue={updateFieldValue}
            value={fieldValues[id]}
            {...config[id]}
          />
        );
      default:
        return (
          <InputText
            id={id}
            key={id}
            label={i18n.fields[id]}
            placeholder={i18n.placeholders[id]}
            setValue={updateFieldValue}
            shouldValidate={shouldValidate}
            value={fieldValues[id]}
            {...config[id]}
          />
        );
    }
  }

  return (
    <Wrapper>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      {dataLayerIsPopulated && <DataLayer data={dataLayer} extended />}
      <Navbar pageName={pageName} />
      <Grid
        gap={6}
        mt={6}
        mx={[3, null, 6, 8]}
        templateColumns={['1fr', null, 'repeat(12, 1fr)']}
      >
        <GridItem colSpan={[1, null, 10, 8]} colStart={[0, null, 2, 3]}>
          <ContentHeader
            subtitle={t('contact-form:contactForm.mainHeadline.subtitle')}
            title={t('contact-form:contactForm.mainHeadline.title')}
          />
        </GridItem>
        <GridItem colSpan={[1, null, 8, 6]} colStart={[0, null, 3, 4]}>
          <Text as="h2" textAlign={['left', 'center']} textStyle="title2">
            {t(
              'application-form-thanks:applicationFormThanks.faqSectionTitle.main'
            )}
            <Box as="span" color="orange.100">
              {t(
                'application-form-thanks:applicationFormThanks.faqSectionTitle.hightlighted'
              )}
            </Box>
          </Text>
          <FAQ category="contact-us" />
          <Text mb={8} textStyle="body4">
            <Box as="span" color="darkBlues.64">
              {t('course-campus:courseCampus.faq.wantMore')}
            </Box>{' '}
            <Link as={GatsbyLink} to={`/${locale}/faq`} variant="emphasis">
              {t('course-campus:courseCampus.faq.linkText')}
            </Link>
          </Text>
          <Text as="h2" mb={4} textAlign={['left', 'center']} textStyle="h1">
            {t('contact-form:contactForm.form.heading.main')}{' '}
            <Box as="span" color="electricBlues.100">
              {t('contact-form:contactForm.form.heading.highlight')}
            </Box>
          </Text>
          <FormLayout>
            <input id="gclid" type="hidden" />
            {generateField('campus')}
            <ContactInfo campus={fieldValues.campus} locale={locale} />
            {Object.keys(config)
              .filter((field) => field !== 'campus')
              .map((id) => generateField(id))}
            <Text5 as="span">
              {t('contact-form:contactForm.requiredFields')}
            </Text5>
            <InputCheckbox
              handleOnChange={setGetUpdatedChecked}
              id="getUpdated"
            >
              {t('contact-form:contactForm.getUpdated')}
            </InputCheckbox>
            <InputButton
              isSending={isSending}
              onClick={handleSumit}
              text={i18n.fields.button}
            />
            <Text mt={2} textAlign="center" textStyle="body5" w="100%">
              {t(
                'application-form:applicationForm.footer.legalAdvice.byApplyingStart'
              )}{' '}
              <Link as={GatsbyLink} to={getLinkByPageName('privacyPolicy')}>
                {t(
                  'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkText'
                )}
              </Link>{' '}
              {t(
                'application-form:applicationForm.footer.legalAdvice.byApplyingConjuntion'
              )}{' '}
              <Link as={GatsbyLink} to={getLinkByPageName('termsOfUse')}>
                {t(
                  'application-form:applicationForm:footer.legalAdvice.termsOfUseLinkText'
                )}
              </Link>
            </Text>
            <Error showError={error.length > 0}>{error}</Error>
          </FormLayout>
        </GridItem>
      </Grid>
      <Footer page={pageName} />
    </Wrapper>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(contact-form)|(course-campus)|(breadcrumbs)|(application-form)|(seo)|(breadcrumbs)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default Contact;
