import { updateDataLayer } from '../../lib/datalayer';

function emitApplyEvent(campus: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'form',
    eventAction: 'click button',
    eventLabel: 'contact us',
    formName: 'contact us',
    campus
  });
}

function emitApplyOkEvent(campus: string, newsletter: boolean): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'form',
    eventAction: 'contact us',
    eventLabel: 'form sent::ok',
    formName: 'contact us',
    formFields: `${campus}::terms and conditions${
      newsletter ? '::newsletter' : ''
    }`,
    campus: campus
  });
}

function emitApplyKoEvent(
  campus: string,
  errorFields: string[] = ['Server Error']
): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'form',
    eventAction: 'contact us',
    eventLabel: 'form sent::ko',
    formName: 'contact us',
    formErrorFields: errorFields.join('::'),
    campus
  });
}

export { emitApplyEvent, emitApplyKoEvent, emitApplyOkEvent };
