import styled from 'styled-components';
import config from '../../components/config';

export default styled.section`
  .layout {
    padding-top: 3.2rem;
  }

  .section-title {
    color: ${config.palette.darkBlueTransparent('0.6')};
    display: block;
    margin-bottom: 2.4rem;
    width: 100%;

    &.col-2 {
      margin-bottom: 3.2rem;
    }

    div {
      color: ${config.palette.darkBlueTransparent('0.6')};
    }
  }

  .col-2 {
    width: calc(50% - 1.6rem);
  }

  & > footer {
    margin-top: 12rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    .layout {
      padding-top: 2.4rem;
    }

    .col-2 {
      margin-bottom: 2.4rem;
      width: 100%;
    }

    & > footer {
      margin-top: 6.4rem;
    }
  }
`;
